import {
  Box,
  Grid,
  IconButton,
  LinearProgress,
  MenuItem,
  Select,
  TableContainer,
  TextField,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Add, Delete } from "@material-ui/icons";
import RefreshIcon from "@material-ui/icons/Refresh";
import { Pagination } from "@material-ui/lab";
import moment from "moment";
import "moment/locale/pt-br";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { postAuthMeAction } from "../../../actions/actions";
import { APP_CONFIG } from "../../../constants/config";
import "../../../fonts/Montserrat-SemiBold.otf";
import useAuth from "../../../hooks/useAuth";
import useDebounce from "../../../hooks/useDebounce";
import {
  deleteBeneficio,
  getBeneficios,
} from "../../../services/beneficiarios";
import px2vw from "../../../utils/px2vw";

import CustomButton from "../../../components/CustomButton/CustomButton";
import CustomTable from "../../../components/CustomTable/CustomTable";
import { MenuOptionsTable } from "../components/MenuOptionsTable";
import { ModalManager } from "../components/ModalManager";
import TableHeaderButton from "../components/TableHeaderButton";

moment.locale("pt-br");

const columns = [
  { headerText: "NOME", key: "nome_beneficio" },
  {
    headerText: "Tipo",
    key: "tipo",
    CustomValue: (text) => (
      <Typography align="center">
        {text === "cartao" ? "Cartão" : "Voucher"}
      </Typography>
    ),
  },
  { headerText: "Documento", key: "documento" },
  {
    headerText: "Prefeitura",
    key: "",
    FullObject: (obj) => `${obj?.nome_prefeitura} - ${obj.sigla}`,
  },
  {
    headerText: "Criado em",
    key: "created_at",
    CustomValue: (text) => (
      <Typography align="center">
        {moment(text).format("DD/MM/YYYY")}
      </Typography>
    ),
  },
  { headerText: "", key: "menu" },
];

export default function ListaBeneficios() {
  const token = useAuth();
  const dispatch = useDispatch();
  const id = useParams()?.id ?? "";
  const [loading, setLoading] = useState(false);
  const [listaContas, setListaContas] = useState();
  const [filter, setFilter] = useState({
    nome_beneficio: "",
    tipo: " ",
    nome_prefeitura: "",
    sigla: "",
    documento: "",
    external_id: "",
  });
  const debouncedFilter = useDebounce(filter, 800);
  const [page, setPage] = useState(1);
  const [showModalNovoCadastro, setShowModalNovoCadastro] = useState(false);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
    headerContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      marginBottom: "25px",
      width: px2vw("100%"),
      "@media (max-width: 1440px)": {
        width: "950px",
      },
      "@media (max-width: 1280px)": {
        width: "850px",
      },
    },
    tableContainer: { marginTop: "1px" },
    pageTitle: {
      color: APP_CONFIG.mainCollors.primary,
      fontFamily: "Montserrat-SemiBold",
    },
  }))();

  const resetFilters = () => {
    setFilter({
      nome_beneficio: "",
      tipo: " ",
      nome_prefeitura: "",
      sigla: "",
      documento: "",
      external_id: "",
    });
  };

  const filters = `nome_beneficio=${filter.nome_beneficio}&tipo=${filter.tipo}&nome_prefeitura=${filter.nome_prefeitura}&sigla=${filter.sigla}&documento=${filter.documento}&external_id=${filter.external_id}`;

  const getData = async (token, page = 1) => {
    setLoading(true);
    try {
      const { data } = await getBeneficios(token, id, page, "", filters);
      console.log(data);
      setListaContas(data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData(token, page);
  }, [token, page, debouncedFilter]);

  useEffect(() => {
    dispatch(postAuthMeAction(token));
  }, [token, dispatch]);

  return (
    <Box className={useStyles.root}>
      <Box className={useStyles.headerContainer}>
        <Box
          style={{
            marginBottom: "20px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography className={useStyles.pageTitle}>Benefícios</Typography>

          <Box style={{ alignSelf: "flex-end" }}>
            <IconButton
              style={{
                backgroundColor: APP_CONFIG.mainCollors.backgrounds,
                color: APP_CONFIG.mainCollors.primary,
              }}
              onClick={() => window.location.reload(false)}
            >
              <RefreshIcon></RefreshIcon>
            </IconButton>
          </Box>
        </Box>

        <Box
          style={{
            width: "100%",
            backgroundColor: APP_CONFIG.mainCollors.backgrounds,
            borderTopLeftRadius: 27,
            borderTopRightRadius: 27,
          }}
        >
          <Box style={{ margin: 30 }}>
            <Grid
              container
              spacing={3}
              style={{ alignItems: "center", marginBottom: "8px" }}
            >
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  placeholder="Pesquisar por nome"
                  size="small"
                  variant="outlined"
                  style={{
                    marginRight: "10px",
                  }}
                  value={filter.nome_beneficio}
                  onChange={(e) => {
                    setPage(1);
                    setFilter((prev) => ({
                      ...prev,
                      nome_beneficio: e.target.value,
                    }));
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <TextField
                  fullWidth
                  placeholder="Pesquisar por ID"
                  size="small"
                  variant="outlined"
                  style={{
                    marginRight: "10px",
                  }}
                  value={filter.external_id}
                  onChange={(e) => {
                    setPage(1);
                    setFilter((prev) => ({
                      ...prev,
                      external_id: e.target.value,
                    }));
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <Select
                  variant="outlined"
                  fullWidth
                  required
                  value={filter.tipo}
                  onChange={(e) => {
                    setPage(1);
                    setFilter((prev) => ({
                      ...prev,
                      tipo: e.target.value,
                    }));
                  }}
                >
                  <MenuItem value={" "}>Tipo</MenuItem>
                  <MenuItem value={"beneficiario"}>Voucher</MenuItem>
                  <MenuItem value={"cartao"}>Cartão</MenuItem>
                </Select>
              </Grid>

              <TableHeaderButton
                text="Novo cadastro"
                onClick={() => setShowModalNovoCadastro(true)}
                Icon={Add}
              />
            </Grid>

            <Grid
              container
              spacing={3}
              style={{ alignItems: "center", marginBottom: "8px" }}
            >
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  placeholder="Pesquisar por prefeitura"
                  size="small"
                  variant="outlined"
                  style={{
                    marginRight: "10px",
                  }}
                  value={filter.prefeitura}
                  onChange={(e) => {
                    setPage(1);
                    setFilter((prev) => ({
                      ...prev,
                      prefeitura: e.target.value,
                    }));
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  placeholder="Pesquisar por sigla"
                  size="small"
                  variant="outlined"
                  style={{
                    marginRight: "10px",
                  }}
                  value={filter.sigla}
                  onChange={(e) => {
                    setPage(1);
                    setFilter((prev) => ({ ...prev, sigla: e.target.value }));
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  placeholder="Pesquisar por documento"
                  size="small"
                  variant="outlined"
                  style={{
                    marginRight: "10px",
                  }}
                  value={filter.documento}
                  onChange={(e) => {
                    setPage(1);
                    setFilter((prev) => ({
                      ...prev,
                      documento: e.target.value,
                    }));
                  }}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} sm={2}>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  height: "100%",
                  width: "100%",
                }}
              >
                <CustomButton color="red" onClick={resetFilters}>
                  <Box display="flex" alignItems="center">
                    <Delete />
                    Limpar
                  </Box>
                </CustomButton>
              </Box>
            </Grid>
          </Box>
        </Box>

        <Box className={useStyles.tableContainer}>
          {!loading && listaContas?.data && listaContas?.per_page ? (
            <Box minWidth={!matches ? "800px" : null}>
              <TableContainer style={{ overflowX: "auto" }}>
                <CustomTable
                  columns={columns}
                  data={listaContas?.data}
                  Editar={({ row }) => (
                    <MenuOptionsTable
                      row={row}
                      getData={getData}
                      deleteCallback={deleteBeneficio}
                      editType="beneficio"
                    />
                  )}
                />
              </TableContainer>
            </Box>
          ) : (
            <Box>
              <LinearProgress color="secondary" />
            </Box>
          )}

          <Box
            display="flex"
            alignSelf="flex-end"
            marginTop="8px"
            justifyContent="space-between"
          >
            <Pagination
              variant="outlined"
              color="secondary"
              size="large"
              count={listaContas?.last_page}
              onChange={(e, value) => setPage(value)}
              page={page}
            />
          </Box>
        </Box>
      </Box>

      <ModalManager.NovoCadastro
        tipo="beneficio"
        show={showModalNovoCadastro}
        setShow={setShowModalNovoCadastro}
        getData={getData}
      />
    </Box>
  );
}
