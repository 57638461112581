import moment from "moment";
import { useState } from "react";
import { useParams } from "react-router-dom";

import useAuth from "../../../../hooks/useAuth";
import {
  postAddBeneficiario,
  postAddBeneficio,
  postAddCartao,
  postAddLoteBeneficiarios,
  postAddLoteCartoes,
  postAddLotePagamentoCartao,
  postAddLotePagamentoEstabelecimento,
  postAddLotePagamentoVoucher,
  postAddLoteVouchers,
  postAddVoucher,
  putUpdateBeneficiario,
  putUpdateBeneficio,
} from "../../../../services/beneficiarios";

export function useNovoCadastroRequests(
  tipo, //beneficiario, cartao, voucher
  update = false,
  data = {}
) {
  const token = useAuth();
  const id = useParams()?.id ?? "";
  const [errors, setErrors] = useState({});
  const [conta, setConta] = useState({
    beneficiario: {
      nome: data?.nome,
      email: data?.email,
      data_nascimento: data?.data_nascimento
        ? moment(data.data_nascimento, "YYYY-MM-DD").format("DD/MM/YYYY")
        : "",
      documento: data?.documento,
      celular: data?.celular,
    },
    endereco: {
      cep: data?.concorrencia_endereco?.cep,
      rua: data?.concorrencia_endereco?.rua,
      numero: data?.concorrencia_endereco?.numero,
      complemento: data?.concorrencia_endereco?.complemento,
      bairro: data?.concorrencia_endereco?.bairro,
      cidade: data?.concorrencia_endereco?.cidade,
      estado: data?.concorrencia_endereco?.estado,
    },
    cartao: {
      documento: data?.user?.documento,
      data_solicitacao: "",
    },
    voucher: {
      documento: data?.documento,
      chave_pix: data?.chave_pix,
    },
    beneficio: {
      nome_prefeitura: data?.nome_prefeitura,
      nome_beneficio: data?.nome_beneficio,
      sigla: data?.sigla,
      documento: data?.documento,
      cdProduto: data?.external_id,
      tipo: data?.tipo,
    },
    tipo_beneficio_id: "",
  });

  function resetFields() {
    setConta({ beneficiario: {}, endereco: {}, cartao: {}, voucher: {} });
  }

  async function requestHandler() {
    setErrors({});
    if (update) {
      if (tipo === "beneficiario") {
        await putUpdateBeneficiario(token, data?.id, {
          beneficiario: conta.beneficiario,
          endereco: conta.endereco,
        });
      }

      if (tipo === "beneficio") {
        await putUpdateBeneficio(token, data?.id, id, conta.beneficio);
      }
    } else {
      if (tipo === "beneficiario") {
        await postAddBeneficiario(token, id, {
          beneficiario: conta.beneficiario,
          endereco: conta.endereco,
        });
      }

      if (tipo === "cartao") {
        await postAddCartao(token, conta.tipo_beneficio_id, conta.cartao);
      }

      if (tipo === "voucher") {
        await postAddVoucher(token, conta.tipo_beneficio_id, conta.voucher);
      }

      if (tipo === "beneficio") {
        await postAddBeneficio(token, id, conta.beneficio);
      }
    }
  }

  return { errors, setErrors, conta, setConta, requestHandler, resetFields };
}

export function useLoteCadastroRequests(
  tipo //beneficiario, cartao, voucher, pagamento_cartao, pagamento_estabelecimento, pagamento_voucher
) {
  const token = useAuth();
  const id = useParams()?.id ?? "";
  const [conta, setConta] = useState({
    descricao: "",
    data_pagamento: "",
  });

  async function requestHandler(file) {
    if (tipo === "beneficiario") {
      await postAddLoteBeneficiarios(token, id, file);
    }
    if (tipo === "cartao") {
      await postAddLoteCartoes(token, id, file);
    }
    if (tipo === "voucher") {
      await postAddLoteVouchers(token, id, file);
    }
    if (tipo === "pagamento_cartao") {
      await postAddLotePagamentoCartao(
        token,
        id,
        file,
        conta.descricao,
        conta.data_pagamento
      );
    }
    if (tipo === "pagamento_estabelecimento") {
      await postAddLotePagamentoEstabelecimento(
        token,
        id,
        file,
        conta.descricao,
        conta.data_pagamento
      );
    }
    if (tipo === "pagamento_voucher") {
      await postAddLotePagamentoVoucher(
        token,
        id,
        file,
        conta.descricao,
        conta.data_pagamento
      );
    }
  }

  return { conta, setConta, requestHandler };
}
