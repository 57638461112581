import {
  Box,
  Checkbox,
  Grid,
  IconButton,
  LinearProgress,
  MenuItem,
  Select,
  TableContainer,
  TextField,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Check, Close, Delete } from "@material-ui/icons";
import RefreshIcon from "@material-ui/icons/Refresh";
import { Modal } from "@mui/material";
import moment from "moment";
import "moment/locale/pt-br";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { postAuthMeAction } from "../../../../actions/actions";
import { APP_CONFIG } from "../../../../constants/config";
import useAuth from "../../../../hooks/useAuth";
import useDebounce from "../../../../hooks/useDebounce";
import {
  getAuditoriaPagamentoEstabelecimento,
  postAuditoriaPagamentoEstabelecimento,
} from "../../../../services/beneficiarios";
import { errorMessageHelper } from "../../../../utils/errorMessageHelper";
import px2vw from "../../../../utils/px2vw";

import CustomButton from "../../../../components/CustomButton/CustomButton";
import CustomCollapseTable from "../../../../components/CustomCollapseTable/CustomCollapseTable";
import TableHeaderButton from "../../components/TableHeaderButton";

moment.locale("pt-br");

const itemColumns = [
  { headerText: "Descrição", key: "descricao" },
  {
    headerText: "Valor",
    key: "valor",
    CustomValue: (value) => (
      <>
        <Typography>
          R${" "}
          {parseFloat(value).toLocaleString("pt-br", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Typography>
      </>
    ),
  },
  { headerText: "Tipo", key: "tipoOperacao" },
];

const startDate = moment().startOf("month").format("YYYY-MM-DD");
const endDate = moment().endOf("month").format("YYYY-MM-DD");

export default function AuditoriaPagamentoEstabelecimento() {
  const token = useAuth();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [listaContas, setListaContas] = useState([]);
  const [filter, setFilter] = useState({
    data_inicio: startDate,
    data_fim: endDate,
    tipoData: 1,
  });
  const debouncedFilter = useDebounce(filter, 800);
  const [page, setPage] = useState(1);
  const [showModalAprovar, setShowModalAprovar] = useState(false);
  const [registros, setRegistros] = useState([]);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
    headerContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      marginBottom: "25px",
      width: px2vw("100%"),
      "@media (max-width: 1440px)": {
        width: "950px",
      },
      "@media (max-width: 1280px)": {
        width: "850px",
      },
    },
    tableContainer: { marginTop: "1px" },
    pageTitle: {
      color: APP_CONFIG.mainCollors.primary,
      fontFamily: "Montserrat-SemiBold",
    },
  }))();

  const columns = [
    {
      headerText: "",
      key: "",
      FullObject: (obj) => {
        return (
          <>
            <Box>
              <Checkbox
                color="primary"
                checked={registros.some(
                  (item) => item?.idReembolso === obj?.idReembolso
                )}
                onChange={() => {
                  if (
                    registros.some(
                      (item) => item?.idReembolso === obj?.idReembolso
                    )
                  ) {
                    setRegistros(
                      registros.filter(
                        (item) => item?.idReembolso !== obj?.idReembolso
                      )
                    );
                  } else {
                    setRegistros([...registros, obj]);
                  }
                }}
              />
            </Box>
          </>
        );
      },
    },
    { headerText: "ID", key: "idReembolso" },
    { headerText: "Vencimento", key: "dtVencimento" },
    { headerText: "Fechamento", key: "dtFechamento" },
    {
      headerText: "Valor Total",
      key: "vlReembolso",
      CustomValue: (value) => (
        <>
          <Typography>
            R${" "}
            {parseFloat(value).toLocaleString("pt-br", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Typography>
        </>
      ),
    },
    { headerText: "Status", key: "statusReembolso" },
    {
      headerText: "Documento",
      key: "",
      FullObject: (obj) =>
        obj?.conta_db
          ? `${obj?.conta_db?.cnpj ?? obj?.conta_db?.documento}`
          : "",
    },
    {
      headerText: "Nome",
      key: "",
      FullObject: (obj) =>
        obj?.conta_db
          ? `${obj?.conta_db?.razao_social ?? obj?.conta_db?.nome}`
          : "",
    },
    {
      headerText: "Quantidade",
      key: "qtd",
      CustomValue: (v) => (
        <Typography style={{ textAlign: "center" }}>{v}</Typography>
      ),
    },
  ];

  const resetFilters = () => {
    setRegistros([]);
    setFilter({
      data_inicio: startDate,
      data_fim: endDate,
      tipoData: 1,
    });
  };

  const filters = `data_inicio=${filter.data_inicio}&data_fim=${filter.data_fim}&tipoData=${filter.tipoData}`;

  const getData = async (token, page = 1) => {
    setLoading(true);
    try {
      const { data } = await getAuditoriaPagamentoEstabelecimento(
        token,
        page,
        filters
      );
      setListaContas(data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleAprovarPagamento = async (descricao, data_pagamento) => {
    setLoading(true);
    try {
      await postAuditoriaPagamentoEstabelecimento(
        token,
        descricao,
        data_pagamento,
        registros
      );
      toast.success("Pagamentos aprovados");
      await getData(token);
      setRegistros([]);
    } catch (err) {
      console.log(err);
      toast.error(errorMessageHelper(err));
    } finally {
      setShowModalAprovar(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    getData(token, page);
  }, [token, page, debouncedFilter]);

  useEffect(() => {
    dispatch(postAuthMeAction(token));
  }, [token, dispatch]);

  return (
    <Box className={useStyles.root}>
      <Box className={useStyles.headerContainer}>
        <Box
          style={{
            marginBottom: "20px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography className={useStyles.pageTitle}>
            Folha de Pagamento para Estabelecimentos
          </Typography>

          <Box style={{ alignSelf: "flex-end" }}>
            <IconButton
              style={{
                backgroundColor: APP_CONFIG.mainCollors.backgrounds,
                color: APP_CONFIG.mainCollors.primary,
              }}
              onClick={() => window.location.reload(false)}
            >
              <RefreshIcon></RefreshIcon>
            </IconButton>
          </Box>
        </Box>

        <Box
          style={{
            width: "100%",
            backgroundColor: APP_CONFIG.mainCollors.backgrounds,
            borderTopLeftRadius: 27,
            borderTopRightRadius: 27,
          }}
        >
          <Box style={{ margin: 30 }}>
            <Grid
              container
              spacing={3}
              style={{ alignItems: "center", marginBottom: "8px" }}
            >
              <Grid item xs={12} sm={4}>
                <Select
                  variant="outlined"
                  fullWidth
                  required
                  value={filter.tipoData}
                  onChange={(e) => {
                    setPage(1);
                    setFilter((prev) => ({
                      ...prev,
                      tipoData: e.target.value,
                    }));
                  }}
                >
                  <MenuItem value={1}>
                    Pesquisar por data de fechamento
                  </MenuItem>
                  <MenuItem value={2} Pesquisar por>
                    Pesquisar por data de vencimento
                  </MenuItem>
                </Select>
              </Grid>

              <Grid item xs={12} sm={3}>
                <TextField
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                    pattern: "d {4}- d {2}- d {2} ",
                  }}
                  type="date"
                  label="Data inicial"
                  value={filters.data_inicio}
                  onChange={(e) => {
                    setPage(1);
                    setFilter((prev) => ({
                      ...prev,
                      data_inicio: e.target.value,
                    }));
                  }}
                  defaultValue={startDate}
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <TextField
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                    pattern: "d {4}- d {2}- d {2} ",
                  }}
                  type="date"
                  label="Data final"
                  value={filters.data_fim}
                  onChange={(e) => {
                    setPage(1);
                    setFilter((prev) => ({
                      ...prev,
                      data_fim: e.target.value,
                    }));
                  }}
                  defaultValue={endDate}
                />
              </Grid>

              <TableHeaderButton
                text="Criar pagamento"
                onClick={() => setShowModalAprovar(true)}
                Icon={Check}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  height: "100%",
                  width: "100%",
                }}
              >
                <CustomButton color="red" onClick={resetFilters}>
                  <Box display="flex" alignItems="center">
                    <Delete />
                    Limpar
                  </Box>
                </CustomButton>
              </Box>
            </Grid>
          </Box>
        </Box>

        <Box className={useStyles.tableContainer}>
          {/* {!loading && listaContas?.data && listaContas?.per_page ? ( */}
          {!loading ? (
            <Box minWidth={!matches ? "800px" : null}>
              <TableContainer style={{ overflowX: "auto" }}>
                <CustomCollapseTable
                  // data={listaContas?.data}
                  data={listaContas ?? []}
                  columns={columns}
                  itemColumns={itemColumns}
                />
              </TableContainer>
            </Box>
          ) : (
            <Box>
              <LinearProgress color="secondary" />
            </Box>
          )}

          {/* <Box
            display="flex"
            alignSelf="flex-end"
            marginTop="8px"
            justifyContent="space-between"
          >
            <Pagination
              variant="outlined"
              color="secondary"
              size="large"
              count={listaContas?.last_page}
              onChange={(e, value) => setPage(value)}
              page={page}
            />
          </Box> */}
        </Box>
      </Box>

      <ModalAprovar
        show={showModalAprovar}
        setShow={setShowModalAprovar}
        length={registros.length}
        handleAprovarPagamento={handleAprovarPagamento}
      />
    </Box>
  );
}

function ModalAprovar({
  show = false,
  setShow = () => null,
  length = 0,
  handleAprovarPagamento = () => null,
}) {
  const classes = makeStyles((theme) => ({
    modal: {
      outline: " none",
      display: "flex",
      flexDirection: "column",
      alignSelf: "center",
      position: "absolute",
      top: "10%",
      left: "35%",
      width: "30%",
      height: "80%",
      backgroundColor: "white",
      border: "0px solid #000",
      boxShadow: 24,
    },
    closeModalButton: {
      alignSelf: "end",
      padding: "5px",
      "&:hover": {
        backgroundColor: APP_CONFIG.mainCollors.primaryVariant,
        cursor: "pointer",
      },
    },
  }))();
  const [descricao, setDescricao] = useState("");
  const [data_pagamento, setData_pagamento] = useState("");

  return (
    <Modal open={show} onBackdropClick={() => setShow(false)}>
      <Box className={classes.modal}>
        <Box
          className={classes.closeModalButton}
          onClick={() => setShow(false)}
        >
          <Close />
        </Box>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            marginTop: "30px",
          }}
        >
          <Typography
            style={{
              fontFamily: "Montserrat-ExtraBold",
              fontSize: "16px",
              color: APP_CONFIG.mainCollors.primary,
              fontWeight: "bold",
            }}
          >
            {length === 0
              ? "Selecione pagamentos para poder aprovar"
              : `Você irá autorizar ${length} pagamento${
                  length === 1 ? "" : "s"
                } selecionado${length === 1 ? "" : "s"}`}
          </Typography>

          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "30px",
              alignItems: "center",
            }}
          >
            <TextField
              required
              fullWidth
              variant="outlined"
              label="Descrição"
              value={descricao}
              onChange={(e) => setDescricao(e.target.value)}
            />

            <Box
              style={{
                width: "100%",
                marginTop: "24px",
              }}
            >
              <TextField
                required
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                  pattern: "d {4}- d {2}- d {2} ",
                }}
                type="date"
                label="Data de pagamento"
                value={data_pagamento}
                onChange={(e) => setData_pagamento(e.target.value)}
              />
            </Box>

            <Box style={{ marginTop: "24px" }}>
              <CustomButton
                variant="contained"
                color="purple"
                onClick={() =>
                  handleAprovarPagamento(descricao, data_pagamento)
                }
                disabled={length === 0}
              >
                <Typography
                  style={{
                    fontFamily: "Montserrat-Regular",
                    fontSize: "14px",
                    color: "white",
                  }}
                >
                  Aprovar
                </Typography>
              </CustomButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
