import { Box, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

import { loadPermissao, postAuthMeAction } from "../actions/actions";
import CustomSideBar from "../components/CustomSideBar/CustomSideBar";
import { APP_CONFIG } from "../constants/config";
import useAuth from "../hooks/useAuth";
import px2vw from "../utils/px2vw";

import AntecipacaoSalarialItemPage from "../modules/AntecipacaoSalarial/pages/AntecipacaoSalarialItem";
import AntecipacaoSalarialListPage from "../modules/AntecipacaoSalarial/pages/AntecipacaoSalarialList";
import ProposalAntecipacaoSalarialAccounts from "../modules/AntecipacaoSalarialProposal/pages/ProposalAntecipacaoSalarialAccounts";
import FinancialSupportItemPage from "../modules/FinancialSupport/pages/FinancialSupportItem";
import FinancialSupportListPage from "../modules/FinancialSupport/pages/FinancialSupportList";
import ProposalFinancialSupportAccounts from "../modules/FinancialSupportProposal/pages/ProposalFinancialSupportAccounts";

import AccountStatement from "../pages/AccountStatement/AccountStatement";
import AccountSubscriptions from "../pages/AccountSubscriptions/AccountSubscriptions";
import AntecipacaoSalarial from "../pages/AntecipacaoSalarial";
import Blacklist from "../pages/Blacklist/Blacklist";
import ChavesPix from "../pages/ChavesPix/ChavesPix";
import CreditCardBillingList from "../pages/CreditCardBillingList/CreditCardBillingList";
import CriarConta from "../pages/CriarConta/CriarConta";
import Dashboard from "../pages/Dashboard/Dashboard";
import DetalhesPreConta from "../pages/DetalhesPreConta/DetalhesPreConta";
import DetalhesTerminalPOS from "../pages/DetalhesTerminalPOS/DetalhesTerminalPOS";
import EditFees from "../pages/EditFees/EditFees";
import EditFeesPadrao from "../pages/EditFeesPadrao/EditFeesPadrao";
import EditarDadosDaConta from "../pages/EditarDadosDaConta/EditarDadosDaConta";
import EditarDadosDaContaAdquirencia from "../pages/EditarDadosDaContaAdquirencia/EditarDadosDaContaAdquirencia";
import EditarDadosDaContaPj from "../pages/EditarDadosDaContaPj/EditarDadosDaContaPj";
import EditarDadosDaContaPjAdquirencia from "../pages/EditarDadosDaContaPjAdquirencia/EditarDadosDaContaPjAdquirencia";
import FinancialSupport from "../pages/FinancialSupport";
import {
  AuditoriaPagamentoEstabelecimento,
  AutorizarPagamentoBeneficiariosEstabelecimento,
  AutorizarPagamentoBeneficiariosVoucher,
  AutorizarPagamentoContratoAluguel,
  GerenciarPagamentoEstabelecimento,
  ListaArquivosDeLote,
  ListaBeneficiarios,
  ListaBeneficiariosCartao,
  ListaBeneficiariosVoucher,
  ListaBeneficios,
  ListaContratoAluguel,
  ListaTransacoesCartao,
  ListaTransacoesVoucher,
  PagamentoBeneficiariosCartao,
  PagamentoBeneficiariosEstabelecimento,
  PagamentoBeneficiariosVoucher,
  PagamentoContratoAluguel,
  PrintFolhaDePagamento,
} from "../pages/GerenciarContasSecretarias";
import GerenciarListaDeContas from "../pages/GerenciarListaDeContas/GerenciarListaDeContas";
import GerenciarListaPreContas from "../pages/GerenciarListaPreContas/GerenciarListaPreContas";
import GiftCardDetails from "../pages/GiftCardDetails/GiftCardDetails";
import GiftCardsList from "../pages/GiftCardsList/GiftCardsList";
import GiftCardsListAdmin from "../pages/GiftCardsListAdmin/GiftCardsListAdmin";
import JeittoAdm from "../pages/JeittoAdm/index";
import ListPartner from "../pages/ListPartners/ListPartners";
import ListaDeAdministradores from "../pages/ListaDeAdministradores/ListaDeAdministradores";
import ListaDeBanners from "../pages/ListaDeBanners/ListaDeBanners";
import ListaDeCartoes from "../pages/ListaDeCartoes/ListaDeCartoes";
import ListaDeContas from "../pages/ListaDeContas/ListaDeContas";
import ListaDeContasAdquirencia from "../pages/ListaDeContasAdquirencia/ListaDeContasAdquirencia";
import ListaDeContasEstabelecimentos from "../pages/ListaDeContasEstabelecimentos/ListaDeContasEstabelecimentos";
import ListaDeContasSecretarias from "../pages/ListaDeContasSecretarias/ListaDeContasSecretarias";
import ListaDispositivosBloqueados from "../pages/ListaDispositivosBloqueados/ListaDispositivosBloqueados";
import ListaExportacoesSolicitadas from "../pages/ListaExportacoesSolicitadas/ListaExportacoesSolicitadas";
import ListaExtratoAdquirencia from "../pages/ListaExtratoAdquirencia/ListaExtratoAdquirencia";
import ListaFolhaDePagamento from "../pages/ListaFolhaDePagamento/ListaFolhaDePagamento";
import ListaPlanosDeVenda from "../pages/ListaPlanosDeVenda/ListaPlanosDeVenda";
import ListaPlanosDeVendaZoop from "../pages/ListaPlanosDeVendaZoop/ListaPlanosDeVendaZoop";
import ListaRepresentantes from "../pages/ListaRepresentantes/ListaRepresentantes";
import ListaTarifas from "../pages/ListaTarifas/ListaTarifas";
import ListaTerminaisPOS from "../pages/ListaTerminaisPOS/ListaTerminaisPOS";
import ListaTransacoesGerais from "../pages/ListaTransacoesGerais/ListaTransacoesGerais";
import Logs from "../pages/Logs/Logs";
import ManageFees from "../pages/ManageFees/ManageFees";
import ManageFeesPadrao from "../pages/ManageFeesPadrao/ManageFeesPadrao";
import NewAccountFees from "../pages/NewAccountFees/NewAccountFees";
import NewAccountFeesPadrao from "../pages/NewAccountFeesPadrao/NewAccountFeesPadrao";
import Notificacoes from "../pages/Notificacoes/Notificacoes";
import PagamentoConta from "../pages/PagamentoConta/PagamentoConta";
import Payers from "../pages/Payers/Payers";
import PaymentBooklet from "../pages/PaymentBooklet/PaymentBooklet";
import PaymentLink from "../pages/PaymentLink/PaymentLink";
import PaymentSlipList from "../pages/PaymentSlipList/PaymentSlipList";
import PixDetails from "../pages/PixDetails/PixDetails";
import PixTransactions from "../pages/PixTransactions/PixTransactions";
import PlanoDeVendaDetalhesZoop from "../pages/PlanoDeVendasDetalhesZoop/PlanoDeVendasDetalhesZoop";
import PlanoDeVendaDetalhes from "../pages/PlanodeVendaDetalhes/PlanoDeVendaDetalhes";
import ProposalAntecipacaoSalarial from "../pages/ProposalAntecipacaoSalarial";
import ProposalFinancialSupport from "../pages/ProposalFinancialSupport";
import RechargeCellphoneDetails from "../pages/RechargeCellphoneDetails/RechargeCellphoneDetails";
import RechargeCellphoneList from "../pages/RechargeCellphoneList/RechargeCellphoneList";
import RechargeCellphoneListAdmin from "../pages/RechargeCellphoneListAdmin/RechargeCellphoneListAdmin";
import TedDetails from "../pages/TedDetails/TedDetails";
import TedTransactions from "../pages/TedTransactions/TedTransactions";
import TransacaoP2p from "../pages/TransacaoP2p/TransacaoP2p";
import TransacaoPagamentoBoleto from "../pages/TransacaoPagamentoBoleto/TransacaoPagamentoBoleto";
import TransacaoPagamentoConta from "../pages/TransacaoPagamentoConta/TransacaoPagamentoConta";
import TransacaoPix from "../pages/TransacaoPix/TransacaoPix";
import TransacaoTed from "../pages/TransacaoTed/TransacaoTed";
import TransacoesTarifas from "../pages/TransacoesTarifas/TransacoesTarifas";
import TransactionDetails from "../pages/TransactionDetails/TransactionDetails";
import TransactionHistory from "../pages/TransactionHistory/TransactionHistory";
import TransferDetails from "../pages/TransferDetails/TransferDetails";
import TransferHistory from "../pages/TransferHistory/TransferHistory";
import UserPermissions from "../pages/UserPermissions/UserPermissions";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100vh",
    width: px2vw("100%"),

    backgroundColor: "#fff",
  },
  sideBarContainer: {
    display: "flex",
    FlexDirection: "column",
    width: px2vw("25%"),
    height: "100vh",
  },
  contentAreaContainer: {
    backgroundColor: "#fff",
    width: "80%",
    padding: "0px 50px 0px 0px",
    marginLeft: px2vw(50),
    marginTop: px2vw(50),
    marginRight: px2vw(50),
    marginBottom: px2vw(10),
  },
  pageHeaderContainer: {
    backgroundColor: "white",
    width: "75%",
    height: "35%",
  },
}));

const SwitchContents = () => {
  const classes = useStyles();
  const { section, id, subsection, subsectionId } = useParams();
  const token = useAuth();
  const dispatch = useDispatch();
  const me = useSelector((state) => state.me);
  const userPermissao = useSelector((state) => state.userPermissao);
  const [permissoes, setPermissoes] = useState([]);

  useEffect(() => {
    dispatch(postAuthMeAction(token));
  }, [token]);

  if (token) {
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = APP_CONFIG.crispId;
    (function () {
      var d = document;
      var s = d.createElement("script");
      s.src = "https://client.crisp.chat/l.js";
      s.async = 1;
      d.getElementsByTagName("head")[0].appendChild(s);
    })();
  }

  /* setInterval(async () => {
		await dispatch(postRefreshAuthAction(token));
	}, 5000); */

  /* useEffect(() => {
		setInterval(() => {
			dispatch(postRefreshAuthAction(token));
		}, 9000);
	}, [token]); */

  /* const refreshLogin = async () => {
		const resRefreshAuth = await dispatch(postRefreshAuthAction(token));
		if (resRefreshAuth) {
			const login_time = new Date().getTime();
			await dispatch(
				setSessionAuth({ ...resRefreshAuth.data, login_time: login_time })
			);
		}
	}; */

  /* const refreshAuth = async () => {
		const resRefreshAuth = await dispatch(postRefreshAuthAction(auth));
		if (resRefreshAuth) {
			await localStorage.setItem(
				'@auth',
				JSON.stringify({
					...resRefreshAuth.data,
					login_time: new Date().getTime(),
				})
			);
		}
	}; */

  /* useEffect(() => {
		setInterval(() => {
			refreshAuth();
		}, 12000);
	}, []);
 */
  useEffect(() => {
    const { permissao } = userPermissao;
    setPermissoes(permissao.map((item) => item.tipo));
  }, [userPermissao]);

  useEffect(() => {
    if (me.id !== undefined) {
      dispatch(loadPermissao(token, me.id));
    }
  }, [me.id]);

  let content = null;

  switch (section) {
    case "home":
      content = <Dashboard />;
      break;

    case "lista-de-contas":
      content = <ListaDeContas />;
      break;

    case "criar-conta-adquirencia":
      content = <CriarConta />;
      break;

    case "lista-de-contas-adquirencia":
      content = <ListaDeContasAdquirencia />;
      break;

    case "lista-de-contas-estabelecimentos":
      content = <ListaDeContasEstabelecimentos />;
      break;

    case "lista-de-contas-secretarias":
      content = <ListaDeContasSecretarias />;
      break;

    case "lista-de-contas-beneficiarios":
      content = <ListaBeneficiarios />;
      break;

    case "lista-de-contas-beneficios":
      content = <ListaBeneficios />;
      break;

    case "print":
      content = <PrintFolhaDePagamento />;
      break;

    case "gerenciar-pagamento-estabelecimento":
      content = <GerenciarPagamentoEstabelecimento />;
      switch (subsection) {
        case "pagamento-beneficiarios-estabelecimento":
          content = <PagamentoBeneficiariosEstabelecimento />;
          break;
        case "auditoria-pagamento-estabelecimento":
          content = <AuditoriaPagamentoEstabelecimento />;
          break;
        case "autorizar-pagamento-beneficiarios-estabelecimento":
          content = <AutorizarPagamentoBeneficiariosEstabelecimento />;
          break;
        default:
      }
      break;

    case "notificacoes":
      content = <Notificacoes />;
      break;

    case "editar-conta":
      switch (id) {
        default:
          content =
            permissoes.includes(
              "Atendimento - Consulta de dados cadastrais(E-mail, Telefone, Endereço e CPF)"
            ) || permissoes.includes("Administrador - Acesso total") ? (
              <EditarDadosDaConta />
            ) : null;
      }

      break;

    case "editar-conta-pj":
      switch (id) {
        default:
          content =
            permissoes.includes(
              "Atendimento - Consulta de dados cadastrais(E-mail, Telefone, Endereço e CPF)"
            ) || permissoes.includes("Administrador - Acesso total") ? (
              <EditarDadosDaContaPj />
            ) : null;
      }

      break;

    case "editar-conta-adquirencia":
      switch (id) {
        default:
          content =
            permissoes.includes(
              "Atendimento - Consulta de dados cadastrais(E-mail, Telefone, Endereço e CPF)"
            ) || permissoes.includes("Administrador - Acesso total") ? (
              <EditarDadosDaContaAdquirencia />
            ) : null;
      }

      break;

    case "editar-conta-pj-adquirencia":
      switch (id) {
        default:
          content =
            permissoes.includes(
              "Atendimento - Consulta de dados cadastrais(E-mail, Telefone, Endereço e CPF)"
            ) || permissoes.includes("Administrador - Acesso total") ? (
              <EditarDadosDaContaPjAdquirencia />
            ) : null;
      }

      break;

    case "representantes":
      switch (id) {
        default:
          content = <ListaRepresentantes />;
          switch (subsection) {
            case "plano-vendas-representante":
              content = <ListaPlanosDeVenda />;
              break;

            default:
          }
      }
      break;

    case "gerenciar-contas":
      switch (id) {
        default:
          content =
            permissoes.includes(
              "Operações - Histórico de transações, extrato, pix, cobrança recorrente, carnê, boleto, chave pix cadastrada, exceto permissão para aprovação de cadastro, reenvio de token de aprovação"
            ) || permissoes.includes("Administrador - Acesso total") ? (
              <GerenciarListaDeContas />
            ) : null;
          switch (subsection) {
            case "cobrancas-credito":
              content = <CreditCardBillingList />;
              break;

            case "boleto":
              content = <PaymentSlipList />;
              break;

            case "carne":
              content = <PaymentBooklet />;
              break;

            case "link-pagamento":
              content = <PaymentLink />;
              break;

            /* case 'lancamentos-futuros':
							content = <FutureTransactions />;
							break; */

            case "extrato":
              content =
                permissoes.includes("Atendimento - Consulta de extrato") ||
                permissoes.includes("Administrador - Acesso total") ? (
                  <AccountStatement />
                ) : null;
              break;

            case "assinaturas":
              content = <AccountSubscriptions />;
              break;

            case "historico-de-transacoes":
              content =
                permissoes.includes(
                  "Operações - Transações e histórico de transações não concluídas"
                ) || permissoes.includes("Administrador - Acesso total") ? (
                  <TransactionHistory />
                ) : null;

              break;

            case "historico-transferencia":
              content = <TransferHistory />;

              break;

            case "detalhes-transacao":
              content = <TransactionDetails />;

              break;

            case "pagadores":
              content = <Payers />;
              break;

            case "transferencia-ted":
              content = <TedTransactions />;
              break;

            case "pagamento-conta":
              content = <PagamentoConta />;
              break;

            case "transacoes-pix":
              content = <PixTransactions />;
              break;

            case "chaves-pix":
              content = <ChavesPix />;
              break;

            case "gift-cards":
              content = <GiftCardsList />;
              break;

            case "detalhes-gift-card":
              content = <GiftCardDetails />;
              break;

            case "recarga-celular":
              content = <RechargeCellphoneList />;
              break;

            case "folha-de-pagamento":
              content = <ListaFolhaDePagamento />;
              break;

            case "detalhes-recarga":
              content = <RechargeCellphoneDetails />;
              break;

            case "detalhes-transferencia":
              content = <TransferDetails />;
              break;

            case "detalhes-ted":
              content = <TedDetails />;
              break;

            case "detalhes-pix":
              content = <PixDetails />;
              break;

            case "terminais-pos":
              content = <ListaTerminaisPOS />;
              break;

            case "detalhes-terminal-pos":
              content = <DetalhesTerminalPOS />;

              break;

            case "exportacoes-solicitadas":
              content = <ListaExportacoesSolicitadas />;
              break;

            case "tarifas":
              content = <ListaTarifas />;
              break;

            case "extrato-adquirencia":
              content = <ListaExtratoAdquirencia />;
              break;

            case "lista-beneficiarios":
              content = <ListaBeneficiarios />;
              break;

            case "lista-beneficiarios-voucher":
              content = <ListaBeneficiariosVoucher />;
              break;

            case "lista-beneficiarios-cartao":
              content = <ListaBeneficiariosCartao />;
              break;

            case "lista-beneficios":
              content = <ListaBeneficios />;
              break;

            case "lista-contrato-aluguel":
              content = <ListaContratoAluguel />;
              break;

            case "pagamento-beneficiarios-cartao":
              content = <PagamentoBeneficiariosCartao />;
              break;

            case "pagamento-beneficiarios-estabelecimento":
              content = <PagamentoBeneficiariosEstabelecimento />;
              break;

            case "pagamento-beneficiarios-voucher":
              content = <PagamentoBeneficiariosVoucher />;
              break;

            case "pagamento-contrato-aluguel":
              content = <PagamentoContratoAluguel />;
              break;

            case "autorizar-pagamento-beneficiarios-estabelecimento":
              content = <AutorizarPagamentoBeneficiariosEstabelecimento />;
              break;

            case "autorizar-pagamento-beneficiarios-voucher":
              content = <AutorizarPagamentoBeneficiariosVoucher />;
              break;

            case "autorizar-pagamento-contrato-aluguel":
              content = <AutorizarPagamentoContratoAluguel />;
              break;

            case "lista-arquivos-de-lote":
              content = <ListaArquivosDeLote />;
              break;

            default:
          }
      }
      break;

    case "transacoes":
      content = <ListaTransacoesGerais />;
      break;

    case "transacoes-pix":
      content = <TransacaoPix />;
      break;

    case "transacoes-p2p":
      content = <TransacaoP2p />;
      break;

    case "transacoes-ted":
      content = <TransacaoTed />;
      break;

    case "transacoes-pagamento-conta":
      content = <TransacaoPagamentoConta />;
      break;

    case "transacoes-pagamento-boleto":
      content = <TransacaoPagamentoBoleto />;
      break;

    case "transacoes-cartoes":
      content = <ListaTransacoesCartao />;
      break;

    case "transacoes-voucher":
      content = <ListaTransacoesVoucher />;
      break;

    case "lista-de-administradores":
      switch (id) {
        default:
          content =
            permissoes.includes(
              "Operações - Gerencimento de administradores"
            ) || permissoes.includes("Administrador - Acesso total") ? (
              <ListaDeAdministradores />
            ) : null;
          switch (subsection) {
            case "permissoes":
              content =
                permissoes.includes(
                  "Operações - Gerencimento de administradores"
                ) || permissoes.includes("Administrador - Acesso total") ? (
                  <UserPermissions />
                ) : null;
              break;

            default:
          }
      }
      break;

    case "taxas":
      content = <ManageFees />;
      break;

    case "taxa-padrao":
      content = <ManageFeesPadrao />;
      break;

    case "parceiros":
      content = <ListPartner />;
      break;

    /* case 'representantes':
			content = <ListaRepresentantes />;
			break; */

    case "banners":
      content = <ListaDeBanners />;
      break;

    case "plano-vendas-zoop":
      content = <ListaPlanosDeVendaZoop />;
      break;

    /* case 'plano-vendas':
			content = <ListaPlanosDeVenda />;
			break; */

    case "plano-de-venda":
      switch (id) {
        default:
          content = <PlanoDeVendaDetalhes />;
          break;
      }
      break;

    case "plano-de-venda-zoop":
      switch (id) {
        default:
          content = <PlanoDeVendaDetalhesZoop />;
          break;
      }
      break;

    case "plano-vendas":
      switch (id) {
        default:
          content = <ListaPlanosDeVenda />;

          switch (subsection) {
            case "detalhes":
              <PlanoDeVendaDetalhes />;

              break;

            default:
          }
      }
      break;

    case "jeitto":
      content =
        permissoes.includes("Parceiros - Visualizar Jeitto") ||
        permissoes.includes("Administrador - Acesso total") ? (
          <JeittoAdm />
        ) : null;
      break;

    case "recarga-celular-admin":
      content =
        permissoes.includes("Parceiros - Visualizar Recargar") ||
        permissoes.includes("Administrador - Acesso total") ? (
          <RechargeCellphoneListAdmin />
        ) : null;
      break;

    case "gift-cards-admin":
      content =
        permissoes.includes("Parceiros - Visualizar GiftCard") ||
        permissoes.includes("Administrador - Acesso total") ? (
          <GiftCardsListAdmin />
        ) : null;
      break;

    case "logs":
      content =
        permissoes.includes("Operações - Visualizar Logs") ||
        permissoes.includes("Administrador - Acesso total") ? (
          <Logs />
        ) : null;
      break;

    case "taxa":
      switch (subsection) {
        case "editar": {
          switch (id) {
            default:
              content = <EditFees />;
              break;
          }
          break;
        }
        case "editar-padrao": {
          switch (id) {
            default:
              content = <EditFeesPadrao />;
              break;
          }
          break;
        }
        default:
          content = null;
      }
      break;

    case "nova-taxa":
      content = <NewAccountFees />;
      break;

    case "nova-taxa-padrao":
      content = <NewAccountFeesPadrao />;
      break;

    case "transacoes-tarifas":
      content = <TransacoesTarifas />;
      break;

    case "lista-pre-contas":
      content = <GerenciarListaPreContas />;
      break;

    case "lista-dispositivos-bloqueados":
      content = <ListaDispositivosBloqueados />;
      break;

    case "blacklist":
      content = <Blacklist />;
      break;

    case "cartoes":
      content = <ListaDeCartoes />;
      break;

    case "detalhes-pre-conta":
      switch (id) {
        default:
          content = <DetalhesPreConta />;
          break;
      }
      break;

    case "apoio-financeiro":
      content =
        permissoes.includes("Administrador - Acesso total") ||
        permissoes.includes("Crédito - Proposta Apoio Financeiro") ? (
          <ProposalFinancialSupport />
        ) : null;

      if (subsection === "gerenciar-contas") {
        content =
          permissoes.includes("Administrador - Acesso total") ||
          permissoes.includes("Crédito - Proposta Apoio Financeiro") ? (
            <ProposalFinancialSupportAccounts />
          ) : null;
      }

      if (subsection === "info") {
        content =
          permissoes.includes("Administrador - Acesso total") ||
          permissoes.includes("Crédito - Proposta Apoio Financeiro") ? (
            <FinancialSupport />
          ) : null;
      }

      if (subsection === "listagem") {
        content =
          permissoes.includes("Administrador - Acesso total") ||
          permissoes.includes("Crédito - Apoio Financeiro") ? (
            <FinancialSupportListPage />
          ) : null;
      }

      if (subsection === "proposta") {
        content =
          permissoes.includes("Administrador - Acesso total") ||
          permissoes.includes("Crédito - Apoio Financeiro") ? (
            <FinancialSupportItemPage />
          ) : null;
      }
      break;

    case "antecipacao-salarial":
      content =
        permissoes.includes("Administrador - Acesso total") ||
        permissoes.includes("Crédito - Proposta Apoio Financeiro") ? (
          <ProposalAntecipacaoSalarial />
        ) : null;

      if (subsection === "gerenciar-contas") {
        content =
          permissoes.includes("Administrador - Acesso total") ||
          permissoes.includes("Crédito - Proposta Apoio Financeiro") ? (
            <ProposalAntecipacaoSalarialAccounts />
          ) : null;
      }

      if (subsection === "info") {
        content =
          permissoes.includes("Administrador - Acesso total") ||
          permissoes.includes("Crédito - Proposta Apoio Financeiro") ? (
            <AntecipacaoSalarial />
          ) : null;
      }

      if (subsection === "listagem") {
        content =
          permissoes.includes("Administrador - Acesso total") ||
          permissoes.includes("Crédito - Apoio Financeiro") ? (
            <AntecipacaoSalarialListPage />
          ) : null;
      }

      if (subsection === "proposta") {
        content =
          permissoes.includes("Administrador - Acesso total") ||
          permissoes.includes("Crédito - Apoio Financeiro") ? (
            <AntecipacaoSalarialItemPage />
          ) : null;
      }
      break;

    default:
      content = null;
      break;
  }

  if (section === "print")
    return <Box className={classes.contentAreaContainer}>{content}</Box>;

  return (
    <Box className={classes.root}>
      <Box className={classes.sidebarContainer}>
        <CustomSideBar />

        <Box className={classes.pageHeaderContainer}></Box>
      </Box>

      <Box className={classes.contentAreaContainer}>{content}</Box>
    </Box>
  );
};

export default SwitchContents;
