import { MenuItem, Select } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getBeneficios } from "../../../../services/beneficiarios";

import useAuth from "../../../../hooks/useAuth";

export default function SelectBeneficio({ state, setState }) {
  const [tiposBeneficio, setTiposBeneficio] = useState([]);
  const token = useAuth();
  const id = useParams()?.id ?? "";

  const getTiposBeneficio = async () => {
    try {
      const { data } = await getBeneficios(token, id);
      setTiposBeneficio(data.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getTiposBeneficio();
  }, []);

  return (
    <Select
      variant="outlined"
      fullWidth
      required
      value={state}
      onChange={setState}
    >
      <MenuItem key={" "} value={" "}>
        Nome do benefício
      </MenuItem>
      {tiposBeneficio.map((item) => (
        <MenuItem key={item?.id} value={item?.id}>
          {item?.nome_beneficio}
        </MenuItem>
      ))}
    </Select>
  );
}
