import { CircularProgress } from "@material-ui/core";
import { ViewList } from "@material-ui/icons";
import { useState } from "react";
import { toast } from "react-toastify";

import { getExportTable } from "../../../../services/beneficiarios";

import TableHeaderButton from "../TableHeaderButton";

export function ExportTableButtons({
  token,
  path, // extrato || aluguel-conta || beneficiario || beneficiario/contas || beneficiario/cartoes-privados || cartao-privado-pagamento || pagamento-estabelecimento || pagamento-aluguel
  page,
  filters,
}) {
  const [loading, setLoading] = useState(false);

  const handleExportTable = async (type) => {
    setLoading(type);
    try {
      const { data } = await getExportTable(token, path, type, page, filters);
      const newWindow = window.open(data?.url, "_blank", "noopener,noreferrer");
      if (newWindow) newWindow.opener = null;
    } catch (err) {
      console.log(err);
      toast.error("Ocorreu um erro ao exportar o arquivo. Tente novamente.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <TableHeaderButton
        text="Exportar"
        onClick={() => handleExportTable("xlsx")}
        Icon={
          loading === "xlsx"
            ? (props) => <CircularProgress size={22} color="white" {...props} />
            : ViewList
        }
      />

      <TableHeaderButton
        text="Exportar PDF"
        onClick={() => handleExportTable("pdf")}
        Icon={
          loading === "pdf"
            ? (props) => <CircularProgress size={22} color="white" {...props} />
            : ViewList
        }
      />
    </>
  );
}
